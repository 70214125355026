import posthog from "posthog-js";

// event_naming
// account_settings:forget_password_button_click or signup_flow:pricing_page_view

// click // - submit // - create // - view // - add
// - invite // - update // - delete // - remove // - start
// - end // - cancel // - fail // - generate // - send

// Categories

// auth e.g auth:signup_start  , auth:lister_onboarding_start

// browse e.g browse:user_search , browse:user_image_view, browse:favorite_add, browse:favorite_click
// map_hide, map_marker_click, waitlist_join,
// specialpageview category   browse:page_view   (lister, property)

// search e.g search:search_query, search:filter_click, search:filter_refine
// lead e.g lead:whatsapp_click, lead:message_sent

export const capture = ({ type, category, event, properties }) => {
  const user_type = !type ? "user" : type === "lister" ? "lister" : "user";
  //                 auth:       user_       signup_start
  posthog.capture(`${category}:${event}`, {
    ...properties,
    user_type,
  });
};

// identifying user

export const identify = ({ id, properties, propertiesOnce }) => {
  posthog.identify(id, properties);
};

export const addGroup = ({ type, id, properties }) => {
  posthog.group(type, id, properties);
};
