import React, { useRef, useState } from "react";
import * as Dialog from "@radix-ui/react-dialog";
import { AiOutlineClose, AiOutlineLoading3Quarters } from "react-icons/ai";
import { AnimatePresence, motion } from "framer-motion";
import { useRouter } from "next/router";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInWithPopup,
  getAdditionalUserInfo,
  sendPasswordResetEmail,
} from "firebase/auth";
import {
  doc,
  getDoc,
  getFirestore,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { authError } from "../../utils/errors/authError";
import * as Toast from "@radix-ui/react-toast";
import { getFunctions, httpsCallable } from "firebase/functions";
import { GoogleAuthProvider } from "firebase/auth";
import Image from "next/image";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { MdOutlineMarkEmailRead } from "react-icons/md";
import * as Sentry from "@sentry/browser";
import Link from "next/link";
import { addGroup, capture, identify } from "../../utils/analytics/capture";

const AuthDialog = ({
  open,
  setOpen,
  active,
  setActive,
  actionMode,
  setActionMode,
  destination,
  setDestination,
  successCallBack,
  setSuccessCallBack,
}) => {
  //  console.log(successCallBack)
  const [openToast, setOpenToast] = useState(false);
  const previouslyFocusedRef = useRef();
  const router = useRouter();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [resetPassword, setResetPassword] = useState(false);
  const [sendingResetLink, setSendingResetLink] = useState(false);
  const [resetLinkSent, setResetLinkSent] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingOtherProvider, setLoadingOtherProvider] = useState(false);
  const auth = getAuth();
  const user = auth.currentUser;
  const db = getFirestore();
  const [passwordShown, setPasswordShown] = useState(false);
  // const contextAuth = useAuth();
  const [error, setError] = useState(null);

  const provider = new GoogleAuthProvider();

  const functions = getFunctions();
  const addUserRole = httpsCallable(functions, "addUserClaim");

  const actionText = () => {
    if (actionMode === "REFERRAL") {
      return "Get your unique referral link";
    } else if (actionMode === "SAVE") {
      return "Save this home";
    } else if (actionMode === "MESSAGE") {
      return "Message this agent";
    } else if (actionMode === "AGENCY-SEARCH") {
      return "Find Real Estate Companies and Agents near me";
    } else if (actionMode.includes("TOWN-WAITLIST")) {
      return (
        "Get updated when new homes come to " +
        actionMode.split("TOWN-WAITLIST-")[1]
      );
    } else {
      return "";
    }
  };

  const giveCode = () => {
    // abcdefghijklmnopqrstuvwxyz
    const DEFAULT_ALPHABET = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    let id;
    function getRandomCharFromAlphabet(alphabet) {
      return alphabet.charAt(Math.floor(Math.random() * alphabet.length));
    }
    function generateId(idDesiredLength, alphabet = DEFAULT_ALPHABET) {
      id = Array.from({ length: idDesiredLength })
        .map(() => {
          return getRandomCharFromAlphabet(alphabet);
        })
        .join("");
      return id;
    }
    const finalCode = generateId(6);
    return finalCode;
  };

  return (
    <>
      <Dialog.Root
        open={open}
        onOpenChange={(open) => {
          // console.log(open)
          if (!open) {
            // console.log(open)
            // console.log(successCallBack);
            // successCallBack()

            setOpen(open);
            setActionMode(null);
          }
        }}
      >
        <Dialog.Trigger asChild></Dialog.Trigger>

        <AnimatePresence>
          {open ? (
            <Dialog.Portal forceMount>
              <Dialog.Overlay forceMount>
                <motion.div
                  key="overlay"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 0.5 }}
                  exit={{
                    opacity: 0,
                    transition: {
                      duration: 0.05,
                    },
                  }}
                  className=" fixed  inset-0 z-30  flex h-screen items-center justify-center   bg-black opacity-50"
                ></motion.div>
              </Dialog.Overlay>

              <Toast.Provider swipeDirection="right">
                <Toast.Root
                  duration={2800}
                  className=" flex w-[80vw] flex-row justify-between space-x-0 rounded-lg border border-red-100  bg-red-100 px-6 py-4 shadow-lg lg:w-auto"
                  open={openToast}
                  onOpenChange={() => {
                    setOpenToast(false);
                    setError(null);
                  }}
                >
                  <AnimatePresence>
                    {open && (
                      <motion.div
                        initial={{ opacity: 0, y: 20, scale: 0.7 }}
                        animate={{ opacity: 1, y: 0, scale: 1 }}
                        exit={{
                          opacity: 0,
                          scale: 0.5,
                          transition: { duration: 0.6 },
                        }}
                        className="flex flex-row"
                      >
                        <div className=" flex flex-col space-y-0">
                          <Toast.Title className="  mb-0 text-base font-semibold text-red-600 ">
                            {authError(error?.errorCode).title}
                          </Toast.Title>
                          <Toast.Description asChild>
                            <span className=" text-sm text-red-600">
                              {authError(error?.errorCode).message}
                            </span>
                          </Toast.Description>
                        </div>
                        <Toast.Action altText="Close">
                          <span
                            onClick={() => {
                              setOpenToast(false);
                            }}
                            className=" invisible ml-8 rounded-md border  border-red-200 bg-transparent px-4 py-1 text-xs font-semibold text-black "
                          >
                            Close
                          </span>
                        </Toast.Action>
                        <Toast.Close />
                      </motion.div>
                    )}
                  </AnimatePresence>
                </Toast.Root>

                <Toast.Viewport className=" fixed bottom-0 right-0 z-40 m-0 mb-5 mr-12 flex max-w-screen-lg flex-col gap-10 lg:mr-10" />
              </Toast.Provider>

              <Dialog.Content
                className="    "
                forceMount
                onOpenAutoFocus={(e) => {
                  e.preventDefault();
                }}
              >
                <motion.div
                  key="modal"
                  initial={{ scale: 0.7 }}
                  animate={{ scale: 1 }}
                  transition={{
                    type: "spring",
                    stiffness: 400,
                    damping: 40,
                    duration: 0.2,
                  }}
                  exit={{
                    opacity: 0,
                    scale: 0.8,
                    transition: {
                      duration: 0.05,
                    },
                  }}
                  className="fixed left-0 top-0 z-30 h-screen w-[100vw] bg-white px-10 py-12 lg:left-[35vw] lg:top-[5vh] lg:h-[90vh] lg:w-[35vw] lg:rounded-md  lg:px-16"
                >
                  <Dialog.Title className=" flex flex-row items-center justify-between  text-3xl font-medium text-black">
                    <span></span>
                    <div className="  flex flex-row items-center space-x-0 ">
                      {/* <div className=" flex h-8 w-8 items-center justify-center rounded-full bg-white lg:h-10 lg:w-10 lg:bg-white">
                        <div className=" relative   h-6  w-6 rounded-full bg-white lg:h-8 lg:w-8 lg:bg-white ">
                          <Image
                            src="/icon/ula-white.png"
                            layout="fill"
                            className=" flex  rounded-full  object-cover  "
                            alt=""
                          />
                        </div>
                      </div> */}
                      <div className=" relative   h-12  w-12 rounded-full bg-white lg:h-12 lg:w-12 lg:bg-white ">
                        <Image
                          src="/icon/ula-white.png"
                          layout="fill"
                          className=" flex  rounded-full  object-cover  "
                          alt=""
                        />
                      </div>
                      <span className="mt-2 select-none font-eastman   text-ula-primary">
                        ula
                      </span>
                    </div>
                    <Dialog.Close asChild>
                      <span className=" flex h-6 w-6 flex-shrink-0  cursor-pointer items-center justify-center rounded-full text-sm hover:bg-gray-200">
                        <AiOutlineClose className=" h-4 w-4 text-gray-500" />
                      </span>
                    </Dialog.Close>
                  </Dialog.Title>
                  {!resetPassword ? (
                    <div className=" mt-4   space-y-4">
                      {actionMode ? (
                        <div className=" w-full  text-center">
                          <span className="  pt-0    text-base text-gray-700">
                            {actionText()}
                          </span>
                        </div>
                      ) : (
                        <div className=" w-full  text-center">
                          <span className="  select-none  pt-0   text-base text-gray-700">
                            Your home search, simplified
                          </span>
                        </div>
                      )}
                      <Dialog.Description className=" mb-3 mt-5 flex flex-row items-baseline justify-center space-x-4 text-black">
                        <span
                          onClick={() => {
                            setActive(0);
                          }}
                          className={
                            " cursor-pointer select-none " +
                            (active === 0
                              ? " text-lg font-medium underline decoration-ula-primary decoration-2 underline-offset-8 "
                              : " text-base text-gray-600")
                          }
                        >
                          Login
                        </span>
                        <span
                          onClick={() => {
                            setActive(1);
                          }}
                          className={
                            " cursor-pointer  select-none" +
                            (active === 1
                              ? " text-lg font-medium underline decoration-ula-primary decoration-2 underline-offset-8 "
                              : " text-base text-gray-600")
                          }
                        >
                          {" "}
                          Create Free Account
                        </span>
                      </Dialog.Description>
                    </div>
                  ) : (
                    <>
                      {!resetLinkSent ? (
                        <Dialog.Description className=" mb-3 mt-5 flex flex-row items-baseline space-x-4 text-black">
                          <span className={"select-none  text-xl"}>
                            Reset your password
                          </span>
                        </Dialog.Description>
                      ) : (
                        <Dialog.Description className=" mb-3 mt-5 flex flex-col items-start justify-start space-y-0 text-black">
                          {/* <div className=" flex  w-full items-center justify-center">
                            <div className=" flex h-20 w-20 items-center justify-center rounded-full bg-green-100 ">
                              <BsPersonCheck className=" h-8 w-8 text-green-800" />
                            </div>
                          </div> */}
                          <h1 className="text-xl ">
                            Forgot password link sent
                          </h1>
                          <span className=" text-left text-gray-700">
                            Check your mail app
                          </span>
                        </Dialog.Description>
                      )}
                    </>
                  )}
                  {!resetPassword ? (
                    <form
                      onSubmit={
                        loading || loadingOtherProvider
                          ? (e) => {
                              e.preventDefault();
                            }
                          : async (e) => {
                              e.preventDefault();
                              setLoading(true);
                              if (active === 0) {
                                signInWithEmailAndPassword(
                                  auth,
                                  email,
                                  password
                                )
                                  .then(async (userCredential) => {
                                    // Signed in
                                    const user = userCredential.user;
                                    const tokenResult =
                                      await auth.currentUser.getIdTokenResult(
                                        true
                                      );
                                    Sentry.setUser({
                                      email: user.email,
                                      id: user.uid,
                                    });
                                    // Analytics Input

                                    identify({
                                      id: user.uid,
                                      properties: {
                                        ula_uid: user.uid,
                                        email_verified:
                                          tokenResult.claims.email_verified,
                                        sign_in_provider_used:
                                          tokenResult.claims.firebase
                                            .sign_in_provider,
                                        manager: tokenResult.claims.manager
                                          ? tokenResult.claims.manager
                                          : false,
                                        normal_user: tokenResult.claims.user
                                          ? tokenResult.claims.user
                                          : false,
                                      },
                                    });

                                    capture({
                                      type: tokenResult.claims.manager
                                        ? "lister"
                                        : "user",
                                      category: "auth",
                                      event: "signin",
                                    });
                                    if (tokenResult.claims.manager) {
                                      addGroup({
                                        type: "lister",
                                        id: user.uid,
                                        properties: {
                                          date_joined:
                                            user.metadata.creationTime,
                                        },
                                      });
                                    } else if (tokenResult.claims.user) {
                                    }
                                    // End Analytics Input
                                    if (tokenResult.claims.manager) {
                                      const docRef = doc(
                                        db,
                                        "Listers",
                                        user.uid
                                      );
                                      const docSnap = await getDoc(docRef);
                                      if (docSnap.exists()) {
                                        let userData = docSnap.data();
                                        if (userData.onboardingComplete) {
                                          // onboarding complete
                                          router
                                            .push("/lister/dashboard")
                                            .then(() => {
                                              setLoading(false);
                                            });
                                        } else {
                                          // take to onboarding
                                          router
                                            .push("/lister/onboarding")
                                            .then(() => {
                                              setLoading(false);
                                            });
                                        }
                                      } else {
                                        console.log("No such lister!");
                                      }
                                    } else if (tokenResult.claims.user) {
                                      setLoading(false);
                                      setEmail("");
                                      setPassword("");
                                      setOpen(false);
                                      setActionMode(null);
                                    }
                                    setEmail("");
                                    setPassword("");
                                    setLoading(false);
                                    setOpen(false);
                                    setActionMode(null);
                                  })
                                  .catch((error) => {
                                    const errorCode = error.code;
                                    const errorMessage = error.message;
                                    // console.log(errorCode)
                                    setError({ errorCode, errorMessage });
                                    setLoading(false);

                                    setOpenToast(true);
                                  });
                              } else if (active === 1) {
                                // sign up
                                createUserWithEmailAndPassword(
                                  auth,
                                  email,
                                  password
                                )
                                  .then(async (userCredential) => {
                                    // Created and signed in
                                    const user = userCredential.user;
                                    await user.reload();
                                    await setDoc(doc(db, "Users", user.uid), {
                                      auth: {
                                        email: user.email,
                                        emailVerified: user.emailVerified,
                                        isAnonymous: user.isAnonymous,
                                        photo: user.photoURL,
                                        phoneNumber: user.phoneNumber,
                                        displayName: user.displayName,
                                        accessToken: user.accessToken,
                                        providerData: user.providerData,
                                        uid: user.uid,
                                        createdTime: user.metadata.creationTime,
                                        lastSignInTime:
                                          user.metadata.lastSignInTime,
                                      },
                                      code: giveCode(),
                                    }).catch((err) => {
                                      console.log(err);
                                    });
                                    let res = await addUserRole({
                                      uid: user.uid,
                                    });
                                    //console.log("success")
                                    const tokenResult =
                                      await user.getIdTokenResult(true);
                                    // console.log(tokenResult.claims)
                                    //await user.getIdToken(true);
                                    identify({
                                      id: user.uid,
                                      properties: {
                                        ula_uid: user.uid,
                                        email_verified:
                                          tokenResult.claims.email_verified,
                                        sign_in_provider_used:
                                          tokenResult.claims.firebase
                                            .sign_in_provider,
                                        manager: tokenResult.claims.manager
                                          ? tokenResult.claims.manager
                                          : false,
                                        normal_user: tokenResult.claims.user
                                          ? tokenResult.claims.user
                                          : false,
                                      },
                                    });

                                    capture({
                                      type: "user",
                                      category: "auth",
                                      event: "signup",
                                    });
                                    setLoading(false);
                                    setEmail("");
                                    setPassword("");
                                    setOpen(false);
                                    setActionMode(null);
                                  })
                                  .catch((error) => {
                                    console.log(error);
                                    const errorCode = error.code;
                                    const errorMessage = error.message;
                                    setError({ errorCode, errorMessage });
                                    setLoading(false);
                                    setOpenToast(true);
                                  });
                              }
                            }
                      }
                      className=" mx-1 my-3 flex flex-col space-y-5"
                    >
                      <div className=" flex flex-col space-y-4">
                        <fieldset className=" flex flex-col space-y-1">
                          <label className=" font-medium text-gray-700">
                            Email
                          </label>
                          <input
                            onChange={(e) => {
                              setEmail(e.target.value);
                            }}
                            value={email}
                            autoFocus={false}
                            autoComplete={active === 0 ? "email" : "email"}
                            type="email"
                            placeholder="Email"
                            className=" w-full rounded-md border border-gray-400 bg-gray-100 px-4 py-3 placeholder-gray-400 focus:border-ula-primary"
                            required
                          ></input>
                        </fieldset>
                        <fieldset className=" flex flex-col space-y-1">
                          <div className="flex flex-row justify-between">
                            <label className=" font-medium text-gray-700">
                              Password
                            </label>
                            <div
                              onClick={() => {
                                setPasswordShown(!passwordShown);
                              }}
                              className="group flex cursor-pointer flex-row items-center space-x-2"
                            >
                              {!passwordShown ? (
                                <FiEye className=" text-gray-700 group-hover:text-gray-700" />
                              ) : (
                                <FiEyeOff className="text-gray-700 group-hover:text-gray-700" />
                              )}
                              {passwordShown ? (
                                <span className=" text-sm text-gray-700 group-hover:text-gray-700">
                                  Hide
                                </span>
                              ) : (
                                <span className=" text-sm text-gray-700 group-hover:text-gray-700">
                                  Show
                                </span>
                              )}
                            </div>
                          </div>
                          <input
                            onChange={(e) => {
                              setPassword(e.target.value);
                            }}
                            value={password}
                            autoFocus={false}
                            autoComplete={
                              active === 0 ? "current-password" : "new-password"
                            }
                            minLength="6"
                            type={passwordShown ? "text" : "password"}
                            placeholder="Password"
                            className=" w-full rounded-md border border-gray-400 bg-gray-100 px-4 py-3 placeholder-gray-400 focus:border-ula-primary"
                            required
                          ></input>
                          {active === 0 ? (
                            <span
                              onClick={() => {
                                setResetPassword(true);
                              }}
                              className=" cursor-pointer text-sm text-gray-700 hover:underline"
                            >
                              Forgot Password
                            </span>
                          ) : null}
                        </fieldset>
                      </div>
                      {!loading ? (
                        <button
                          type="submit"
                          className=" rounded-md bg-ula-primary px-16 py-3 font-medium text-white"
                        >
                          {active === 0 ? "Login" : "Create Account"}
                        </button>
                      ) : (
                        <button className=" flex  items-center justify-center rounded-md bg-ula-primary px-16 py-4 text-white">
                          <AiOutlineLoading3Quarters className="h-4 w-4 animate-spin" />
                        </button>
                      )}

                      {/* <div className=" h-[0.7px] w-full bg-gray-400"></div> */}
                      <div className="flex flex-col space-y-2">
                        <span className=" text-center text-sm text-gray-700">
                          Or
                        </span>
                        <button
                          onClick={
                            loadingOtherProvider
                              ? () => {}
                              : async () => {
                                  // setLoading(true)
                                  setLoadingOtherProvider(true);
                                  signInWithPopup(auth, provider)
                                    .then(async (result) => {
                                      // This gives you a Google Access Token. You can use it to access the Google API.
                                      const credential =
                                        GoogleAuthProvider.credentialFromResult(
                                          result
                                        );
                                      const token = credential.accessToken;
                                      // The signed-in user info.
                                      const user = result.user;
                                      const { isNewUser } =
                                        getAdditionalUserInfo(result);
                                      // console.log(isNewUser)
                                      if (isNewUser) {
                                        const tokenResult =
                                          await auth.currentUser.getIdTokenResult(
                                            true
                                          );
                                        await auth.currentUser.reload();

                                        identify({
                                          id: auth.currentUser.uid,
                                          properties: {
                                            ula_uid: user.uid,
                                            email_verified:
                                              tokenResult.claims.email_verified,
                                            sign_in_provider_used:
                                              tokenResult.claims.firebase
                                                .sign_in_provider,
                                            manager: false,
                                            normal_user: true,
                                          },
                                        });

                                        await setDoc(
                                          doc(db, "Users", user.uid),
                                          {
                                            auth: {
                                              email: user.email,
                                              emailVerified: user.emailVerified,
                                              isAnonymous: user.isAnonymous,
                                              photo: user.photoURL,
                                              phoneNumber: user.phoneNumber,
                                              displayName: user.displayName,
                                              accessToken: user.accessToken,
                                              providerData: user.providerData,
                                              uid: user.uid,
                                              googleToken: token,
                                              createdTime:
                                                user.metadata.creationTime,
                                              lastSignInTime:
                                                user.metadata.lastSignInTime,
                                            },
                                            code: giveCode(),
                                          }
                                        ).catch((err) => {
                                          console.log(err);
                                        });
                                        let res = await addUserRole({
                                          uid: user.uid,
                                        });
                                        await user.getIdToken(true);

                                        capture({
                                          type: "user",
                                          category: "auth",
                                          event: "signup",
                                        });
                                        setLoading(false);
                                        setEmail("");
                                        setPassword("");
                                        setLoadingOtherProvider(false);
                                        setOpen(false);
                                        setActionMode(null);
                                      } else {
                                        // is existing user
                                        const tokenResult =
                                          await auth.currentUser.getIdTokenResult(
                                            true
                                          );
                                        // console.log(tokenResult);

                                        Sentry.setUser({
                                          email: auth.currentUser.email,
                                          id: auth.currentUser.uid,
                                        });

                                        capture({
                                          type: tokenResult.claims.manager
                                            ? "lister"
                                            : "user",
                                          category: "auth",
                                          event: "signin",
                                        });
                                        if (tokenResult.claims.manager) {
                                          identify({
                                            id: auth.currentUser.uid,
                                            properties: {
                                              ula_uid: user.uid,
                                              email_verified:
                                                tokenResult.claims
                                                  .email_verified,
                                              sign_in_provider_used:
                                                tokenResult.claims.firebase
                                                  .sign_in_provider,
                                              manager: true,
                                              normal_user: false,
                                            },
                                          });
                                          addGroup({
                                            type: "lister",
                                            id: user.uid,
                                            properties: {
                                              date_joined:
                                                user.metadata.creationTime,
                                            },
                                          });

                                          await updateDoc(
                                            doc(db, "Listers", user.uid),
                                            {
                                              "auth.email": user.email,
                                              "auth.uid": user.uid,
                                              "auth.emailVerified":
                                                user.emailVerified,
                                              "auth.isAnonymous":
                                                user.isAnonymous,
                                              // photo: user.photoURL,
                                              // displayName: user.displayName,
                                              "auth.accessToken":
                                                user.accessToken,
                                              "auth.providerData":
                                                user.providerData,
                                              "auth.googleToken": token,
                                              "auth.createdTime":
                                                user.metadata.creationTime,
                                              "auth.lastSignInTime":
                                                user.metadata.lastSignInTime,
                                            }
                                          ).catch((err) => {
                                            console.log(err);
                                          });
                                          const docRef = doc(
                                            db,
                                            "Listers",
                                            user.uid
                                          );
                                          const docSnap = await getDoc(docRef);
                                          if (docSnap.exists()) {
                                            let userData = docSnap.data();
                                            if (userData.onboardingComplete) {
                                              // onboarding complete
                                              router
                                                .push("/lister/dashboard")
                                                .then(() => {
                                                  setLoadingOtherProvider(
                                                    false
                                                  );
                                                  setOpen(false);
                                                  setLoading(false);
                                                });
                                            } else {
                                              // take to onboarding
                                              router
                                                .push("/lister/onboarding")
                                                .then(() => {
                                                  setLoadingOtherProvider(
                                                    false
                                                  );
                                                  setOpen(false);
                                                  setLoading(false);
                                                });
                                            }
                                          }
                                        } else if (tokenResult.claims.user) {
                                          identify({
                                            id: auth.currentUser.uid,
                                            properties: {
                                              ula_uid: user.uid,
                                              email_verified:
                                                tokenResult.claims
                                                  .email_verified,
                                              sign_in_provider_used:
                                                tokenResult.claims.firebase
                                                  .sign_in_provider,
                                              manager: false,
                                              normal_user: true,
                                            },
                                          });

                                          // console.log("user");
                                          await updateDoc(
                                            doc(db, "Users", user.uid),
                                            {
                                              "auth.email": user.email,
                                              "auth.uid": user.uid,
                                              "auth.emailVerified":
                                                user.emailVerified,
                                              "auth.isAnonymous":
                                                user.isAnonymous,
                                              "auth.photo": user.photoURL,
                                              "auth.phoneNumber":
                                                user.phoneNumber,
                                              "auth.displayName":
                                                user.displayName,
                                              "auth.accessToken":
                                                user.accessToken,
                                              "auth.providerData":
                                                user.providerData,
                                              "auth.googleToken": token,
                                              "auth.createdTime":
                                                user.metadata.creationTime,
                                              "auth.lastSignInTime":
                                                user.metadata.lastSignInTime,
                                            }
                                          ).catch((err) => {
                                            console.log(err);
                                          });
                                          setLoading(false);
                                          setEmail("");
                                          setPassword("");
                                          setLoadingOtherProvider(false);
                                          setOpen(false);
                                          setActionMode(null);
                                        } else if (
                                          tokenResult.claims.superAdmin
                                        ) {
                                          setLoadingOtherProvider(false);
                                          setOpen(false);
                                          setActionMode(null);
                                        }
                                      }
                                    })
                                    .catch((error) => {
                                      // Handle Errors here.
                                      const errorCode = error.code;
                                      const errorMessage = error.message;
                                      // The email of the user's account used.
                                      const email = error.customData.email;
                                      // The AuthCredential type that was used.
                                      const credential =
                                        GoogleAuthProvider.credentialFromError(
                                          error
                                        );
                                      setError({ errorCode, errorMessage });
                                      setLoading(false);
                                      setLoadingOtherProvider(false);
                                      setOpenToast(true);
                                    });
                                }
                          }
                          type="button"
                          className=" flex w-full flex-row items-center justify-center rounded-md border border-gray-500 py-3 hover:bg-gray-100"
                        >
                          <span className=" relative mr-4 h-5 w-5 text-lg">
                            <Image
                              layout="fill"
                              src="/icon/g-logo.png"
                              alt="Google Signin"
                            />
                          </span>
                          <span className=" text-gray-700">
                            Continue with Google
                          </span>
                        </button>
                      </div>

                      {active === 1 ? (
                        <div className=" flex flex-row  flex-wrap items-center justify-center space-x-1  bg-ula-background-light py-3 text-sm text-ula-secondary lg:flex-nowrap">
                          <span>Are you an agent or company? </span>
                          <Link href="/hodari" passHref>
                            <a
                              onClick={() => {
                                setOpen(false);
                              }}
                              className=" cursor-pointer underline hover:text-gray-900"
                            >
                              Sign up to list on Ula{" "}
                            </a>
                          </Link>
                        </div>
                      ) : null}

                      {/* <span className=" text-gray-600 text-center text-xs">By proceeding, you're agreeing to our Terms and Privacy Policy</span> */}
                    </form>
                  ) : (
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        setSendingResetLink(true);

                        sendPasswordResetEmail(auth, email)
                          .then(() => {
                            setSendingResetLink(false);
                            setResetLinkSent(true);
                          })
                          .catch((error) => {
                            const errorCode = error.code;
                            const errorMessage = error.message;
                            //   console.log(errorCode);
                            //authError(errorCode, errorMessage);
                            setError({ errorCode, errorMessage });
                            setOpenToast(true);
                            setSendingResetLink(false);
                            setResetLinkSent(false);
                          });
                      }}
                      className=" mt-4 flex w-full flex-col space-y-4"
                    >
                      <fieldset className=" mb-1 flex flex-col space-y-1">
                        <label className=" font-medium text-gray-700">
                          Email
                        </label>
                        <input
                          onChange={(e) => {
                            setEmail(e.target.value);
                          }}
                          value={email}
                          autoFocus={false}
                          type="email"
                          disabled={resetLinkSent}
                          placeholder="Email"
                          className=" w-full rounded-md border border-gray-400 bg-gray-100 px-4 py-3 placeholder-gray-400 focus:border-ula-primary"
                          required
                        ></input>
                      </fieldset>
                      {sendingResetLink ? (
                        <button
                          type="button"
                          className=" flex items-center justify-center rounded-md bg-ula-primary py-4 font-medium text-zinc-100"
                        >
                          <AiOutlineLoading3Quarters className="h-4 w-4 animate-spin" />
                        </button>
                      ) : (
                        <>
                          {resetLinkSent ? (
                            <button
                              type="button"
                              className=" flex flex-row items-center justify-center space-x-2 rounded-md bg-green-600 py-3 font-semibold text-zinc-50"
                            >
                              <MdOutlineMarkEmailRead className=" h-5 w-5" />
                              <span>Sent</span>
                            </button>
                          ) : (
                            <>
                              <button
                                type="submit"
                                className="  rounded-md bg-ula-primary py-3 font-medium text-zinc-50"
                              >
                                Send link
                              </button>
                              <div className=" flex items-center  justify-center">
                                <span
                                  className="w-auto cursor-pointer  text-center text-sm hover:underline"
                                  onClick={() => {
                                    setResetPassword(false);
                                  }}
                                >
                                  Cancel
                                </span>
                              </div>
                            </>
                          )}
                        </>
                      )}
                    </form>
                  )}
                </motion.div>
              </Dialog.Content>
            </Dialog.Portal>
          ) : null}
        </AnimatePresence>
      </Dialog.Root>

      <></>
    </>
  );
};

export default AuthDialog;
